<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Reports
      <router-link slot="right" to="/dashboard" class="button">
        Dashboard
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <report-table/>
    </section>
  </div>
</template>

<script>
import mapValues from 'lodash/mapValues'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import { mapActions } from 'vuex'
import ReportTable from '@/components/SSIPLENGG/ReportTable.vue'
export default {
  name: 'Forms',
  components: {
    HeroBar,
    TitleBar,
    ReportTable
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    titleStack () {
      return ['Admin', 'Reports']
    }
  },
  methods: {
    ...mapActions('report', ['fetchItems']),
    submit () {
      this.fetchItems()
    },
    reset () {
      this.form = mapValues(this.form, (item) => {
        if (item && typeof item === 'object') {
          return []
        }
        return null
      })

      this.$buefy.snackbar.open({
        message: 'Reset successfully',
        queue: false
      })
    }
  }
}
</script>
