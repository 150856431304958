<template>
  <section class="hero is-fullheight">
    <card-component title="Generate Report" icon="ballot">
        <form @submit.prevent="submit">
            <b-field horizontal class="is-centered" label="Filter Reports">
            <b-field>
                <b-input
                v-model="form.year"
                icon="calendar"
                placeholder="Year"
                name="year"
                required
                />
            </b-field>
            <b-field message="->Defaults to current month">
                <v-select
                icon="month"
                placeholder="Select a month"
                name="month"
                @input="setMonth"
                :options="months"
                ></v-select>
            </b-field>
            </b-field>
            <b-field horizontal class="is-centered">
            <b-field>
                <v-select
                  label="text"
                  placeholder="Search By Region"
                  :filterable="false"
                  class="control"
                  @input="setRegion"
                  :options="regions"
                  @search="onRegionSearch">
                    <template slot="no-options">
                    type to search region ...
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.text }}
                        </div>
                    </template>
                </v-select>
            </b-field>
            <b-field>
                <v-select
                  label="text"
                  placeholder="Search By Code"
                  :filterable="false"
                  class="style-chooser"
                  @input="setCode"
                  :options="codes"
                  @search="onCodeSearch">
                    <template slot="no-options">
                    type to search code ...
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.text }}
                        </div>
                    </template>
                </v-select>
            </b-field>
            </b-field>
            <hr />
            <b-field horizontal>
            <b-field grouped>
                <div class="control">
                <b-button native-type="submit" type="is-primary"
                    >Submit</b-button
                >
                </div>
            </b-field>
            </b-field>
        </form>
    </card-component>

    <card-component
      v-if="store.total"
      title="Report"
      icon="ballot-outline"
      :class="[
        'has-table',
        store.total == 0 ? 'has-thead-hidden' : ''
      ]"
      header-icon="download"
      @header-icon-click="downloadReport">
        <b-table
            :data="store.items"
            :loading="loading"
            :striped="true"
            :narrowed="true"
            paginated
            backend-pagination
            :total="store.total"
            :per-page="store.per_page"
            @page-change="onPageChange"
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            @sort="onSort">

            <b-table-column field="no" label="Sl No" centered v-slot="props">
                {{ props.row.no }}
            </b-table-column>

            <b-table-column field="code" label="Code" centered v-slot="props">
                {{ props.row.code }}
            </b-table-column>

            <b-table-column field="name" label="Name" centered v-slot="props">
                {{ props.row.name }}
            </b-table-column>

            <b-table-column field="emp_num" label="EMP NUM" centered v-slot="props">
                {{ props.row.emp_no }}
            </b-table-column>

            <b-table-column field="meter_no_1" label="Meter No" centered v-slot="props">
                {{ props.row.meter_no_1 }}
            </b-table-column>

            <b-table-column field="meter_no_1_status" label="MT1 Status" centered v-slot="props">
                {{ props.row.meter_no_1_status }}
            </b-table-column>

            <b-table-column field="prev_read_1" label="Prev Read 1" centered v-slot="props">
                {{ props.row.prev_read_1 }}
            </b-table-column>

            <b-table-column field="meter_no_2" label="Meter No 2" centered v-slot="props">
                {{ props.row.meter_no_2 }}
            </b-table-column>

            <b-table-column field="meter_no_2_status" label="MT2 Status" centered v-slot="props">
                {{ props.row.meter_no_2_status }}
            </b-table-column>

            <b-table-column field="prev_read_2" label="Prev Read 2" centered v-slot="props">
                {{ props.row.prev_read_2 }}
            </b-table-column>

            <b-table-column field="present_date" label="Pres Date" centered v-slot="props">
                {{ props.row.present_date }}
            </b-table-column>

            <b-table-column field="pres_read_1" label="Pres Read 1" centered v-slot="props">
                {{ props.row.pres_read_1 }}
            </b-table-column>

            <b-table-column field="unit_1" label="Unit 1" centered v-slot="props">
                {{ props.row.unit_1 }}
            </b-table-column>

            <b-table-column field="pres_read_2" label="Pres Read 2" centered v-slot="props">
                {{ props.row.pres_read_2 }}
            </b-table-column>

            <b-table-column field="unit_2" label="Unit 2" centered v-slot="props">
                {{ props.row.unit_2 }}
            </b-table-column>

            <b-table-column field="total_units" label="Total Units" centered v-slot="props">
                {{ props.row.total_units }}
            </b-table-column>

            <section slot="empty" class="section">
              <div class="content has-text-grey has-text-centered">
                <template v-if="store.loading">
                  <p>
                    <b-icon icon="dots-horizontal" size="is-large" />
                  </p>
                  <p>Fetching data...</p>
                </template>
                <template v-else>
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large" />
                  </p>
                  <p>Nothing's here&hellip;</p>
                </template>
              </div>
            </section>
        </b-table>
    </card-component>
    <b-loading :is-full-page="true" v-model="loading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import CardComponent from '@/components/CardComponent'
import vSelect from 'vue-select'
import { apiService } from '@/services'
import helper from '@/mixins/helper'

export default {
  name: 'ReportTable',
  mixins: [helper],
  components: {
    CardComponent,
    vSelect
  },
  props: {
    checkable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      data: [],
      total: 0,
      loading: false,
      filter: '',
      sortField: 'id',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      form: {
        year: dayjs().year(),
        month: dayjs().month() + 1,
        code: null,
        region: null
      },
      regions: [], // Used in options
      codes: [], // Used in options
      months: [
        { code: null, label: 'None' },
        { code: 1, label: 'January' },
        { code: 2, label: 'February' },
        { code: 3, label: 'March' },
        { code: 4, label: 'April' },
        { code: 5, label: 'May' },
        { code: 6, label: 'June' },
        { code: 7, label: 'July' },
        { code: 8, label: 'August' },
        { code: 9, label: 'September' },
        { code: 10, label: 'October' },
        { code: 11, label: 'November' },
        { code: 12, label: 'December' }
      ]
    }
  },
  computed: {
    ...mapGetters('report', ['store'])
  },
  methods: {
    ...mapActions('report', ['fetchItems']),
    submit () {
      this.loadAsyncData()
    },
    loadAsyncData () {
      const params = {
        year: this.form.year,
        month: this.form.month,
        filter: this.filter,
        sort_by: this.sortField,
        sort_order: this.sortOrder,
        page: this.page
      }
      if (this.form.code) {
        params.code = this.form.code
      }
      if (this.form.region) {
        params.region = this.form.region
      }
      this.loading = true
      this.fetchItems(params).then(() => {
        this.loading = false
      })
    },
    onPageChange (page) {
      this.page = page
      this.loadAsyncData()
    },
    onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
      this.loadAsyncData()
    },
    onRegionSearch (search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, 'regions', search, this)
      }
    },
    onCodeSearch (search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, 'codes', search, this)
      }
    },
    search: debounce((loading, endpoint, search, vm) => {
      apiService.getAll(`/options/${escape(endpoint)}?q=${escape(search)}`).then(response => {
        vm[endpoint] = response.data
        loading(false)
      }).catch(error => {
        vm.showError(error)
      })
    }, 350),
    setMonth (value) {
      this.form.month = value ? value.code : null
    },
    setRegion (option) {
      this.form.region = option ? option.value : null
    },
    setCode (option) {
      this.form.code = option ? option.value : null
    },
    downloadReport () {
      this.clean(this.form)
      this.loading = true
      apiService.getFile('/export/users', this.form).then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        const timestamp = Date.now()
        fileLink.setAttribute('download', `Report-${timestamp}.xlsx`)
        document.body.appendChild(fileLink)
        fileLink.click()
        this.loading = false
      }).catch(error => {
        this.showError(error)
      })
    }
  }
}
</script>
